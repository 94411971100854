import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const ModeOfStudies = {
  FullTime: 0,
  PartTime: 1,
} as const;

type Keys = keyof typeof ModeOfStudies;

export type ModeOfStudyType = (typeof ModeOfStudies)[Keys];

export const ModeOfStudyOptions = createEnumOptions(ModeOfStudies);
