import React from 'react';
import { ChipProps, Stack } from '@mui/material';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { EmptyCell } from '@utils/empty-cell';

type Props = {
  labels: Array<string | number>;
  optionKey: keyof AppBackendLabels;
} & ChipProps;

export const BackendLabelGroup: React.FunctionComponent<Props> = ({ labels, optionKey, ...chipProps }) => {
  if (labels.length) {
    return (
      <Stack direction="row" spacing={1}>
        {labels.map((el) => (
          <BackendLabel {...chipProps} key={el} value={el} optionKey={optionKey} />
        ))}
      </Stack>
    );
  }
  return <>{EmptyCell}</>;
};
