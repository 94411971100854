import {
  BaseOrderApiModel,
  BaseOrderFormModel,
  baseOrderSanitizeMap,
  baseOrderSerializeMap,
} from '@features/order/base/form-config';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';

export type FormModel = BaseOrderFormModel;

export type ApiModel = BaseOrderApiModel;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  ...baseOrderSanitizeMap,
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  ...baseOrderSerializeMap,
});
