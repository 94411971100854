import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetSensitivePersonalInformationPresenceQuery } from '@api/api-slice';
import { ISensitivePersonalInformationPresence } from './api';

type HookResult = RequestStatusFlags & ISensitivePersonalInformationPresence;

export function useSensitivePersonalInformationPresence(orderId: number): HookResult {
  const { data = { isSpiLocked: true }, ...flags } = useGetSensitivePersonalInformationPresenceQuery(orderId);
  return {
    ...flags,
    ...data,
  };
}
