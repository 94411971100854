import { Moment } from 'moment-timezone';
import { ISession } from '@features/sessions/session.interface';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = Pick<
  ISession,
  'duration' | 'supplierInvoice' | 'funderInvoice' | 'isCancelled' | 'cancellationReason' | 'offeredDate'
> & {
  startMoment: Moment | null;
  cancellationDate: Moment | null;
};

export type ApiModel = Pick<
  ISession,
  | 'startMoment'
  | 'duration'
  | 'supplierInvoice'
  | 'funderInvoice'
  | 'isCancelled'
  | 'cancellationReason'
  | 'cancellationDate'
  | 'offeredDate'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  startMoment: { type: 'date-time', initial: null },
  duration: { type: 'transportable', initial: '' },
  supplierInvoice: { type: 'transportable', initial: '' },
  funderInvoice: { type: 'transportable', initial: '' },
  isCancelled: { type: 'transportable', initial: false },
  cancellationReason: { type: 'backend-label', optionKey: 'sessionsCancellationReasonOptions', initial: null },
  cancellationDate: { type: 'date-time', initial: null },
  offeredDate: { type: 'date-time', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  startMoment: Serializers.dateTime,
  duration: Serializers.float,
  supplierInvoice: Serializers.string,
  funderInvoice: Serializers.string,
  isCancelled: Serializers.boolean,
  cancellationDate: Serializers.dateTime,
  cancellationReason: Serializers.backendValue,
  offeredDate: Serializers.dateTime,
});
