import { FileStruct } from '@utils/superstruct/file-struct';
import { Describe, array, boolean, nullable, number, object, pattern, string, union } from 'superstruct';
import { ISensitivePersonalInformation } from '../sensitive-personal-information.interface';
import { ISODateRegexp } from '@utils/dates/iso-string.type';
import { FileDetailsStruct } from '@utils/superstruct/file-details-struct';
import { FileListStruct } from '@utils/superstruct/file-list-struct';

export const SensitivePersonalInformationStruct: Describe<ISensitivePersonalInformation> = object({
  dsa1PdfFilePath: nullable(string()),
  disabilityTypeIds: array(number()),
  conditionIds: array(number()),
  medicalEvidenceFilesPaths: union([array(FileDetailsStruct), nullable(string())]),
  preAssessmentFormFilePath: nullable(string()),
  dateOfBirth: pattern(string(), ISODateRegexp),
  narFilePath: nullable(string()),
  evidNacdDisabilityFlag: boolean(),
  rejectedDisabilityConditions: nullable(string()),
  rejectedDisabilityTypes: nullable(string()),

  narFile: nullable(FileStruct),
  dsa1pdfFile: nullable(FileStruct),
  medicalEvidenceFiles: union([FileStruct, nullable(FileListStruct)]),
  preAssessmentFormFile: nullable(FileStruct),
});
