import React from 'react';
import { Control, Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { FormControl, FormHelperText, Switch, ToggleButton, ToggleButtonProps } from '@mui/material';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  rules?: UseControllerProps<T>['rules'];
  getLabel: (value: boolean) => string;
  helperText?: string;
} & Omit<ToggleButtonProps, 'value'>;

export const ControlledToggleButton: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  rules,
  getLabel,
  helperText,
  ...fieldProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }): JSX.Element => (
        <FormControl variant="standard" fullWidth>
          <ToggleButton
            {...fieldProps}
            value={value}
            sx={{
              justifyContent: 'flex-start',
              color: `${value && 'var(--color-blue)'}`,
              pt: 0,
              pb: 0,
            }}
            onChange={(): void => onChange(!value)}
          >
            <Switch checked={value} sx={{ pointerEvents: 'none', touchAction: 'none' }} />
            {getLabel(value)}
          </ToggleButton>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
