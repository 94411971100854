import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { AppModal } from '@components/AppModal';
import { useSensitivePersonalInformationPresence } from './use-sensitive-personal-information-presence';
import { useRequestSensitivePersonalInformation } from './use-request-sensitive-personal-information';
import { IOrderNeedsAssessment } from '../order-needs-assessment.interface';
import { useToggle } from '@hooks/use-toggle';
import { AppDrawer } from '@components/AppDrawer';
import { SensitivePersonalInformationDrawer } from './SensitivePersonalInformationDrawer';
import { AppDropdownWithComment } from '@components/inputs/AppDropdownWithComment';
import { RequestReasonsOptions } from './request-reason.type';
import { AppLoadingButton } from '@components/AppLoadingButton';

type Props = {
  order: IOrderNeedsAssessment;
};

export const SensitivePersonalInformation: React.FC<Props> = ({ order }) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();
  const [reason, setReason] = useState('');
  const { isSpiLocked, isLoading: isSpiLoading } = useSensitivePersonalInformationPresence(order.id);
  const { requestHandler, spi, setSpi, isLoading } = useRequestSensitivePersonalInformation();

  async function handleRequest(): Promise<void> {
    try {
      await requestHandler(order.id, reason);
      toggleIsModalOpen();
      toggleIsDrawerOpen();
      setIsLocked(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = (): unknown => (isLocked ? toggleIsModalOpen() : toggleIsDrawerOpen());

  const isRequestButtonDisabled = reason === '' || isLoading;

  useEffect(() => {
    setIsLocked(isSpiLocked);
  }, [isSpiLocked]);

  return (
    <>
      <AppLoadingButton
        isLoading={isSpiLoading}
        onClick={handleClick}
        variant="outlined"
        fullWidth
        endIcon={isLocked ? <LockIcon /> : <LockOpenIcon />}
      >
        Open Sensitive Personal Information
      </AppLoadingButton>

      <AppDrawer open={isDrawerOpen} onClose={toggleIsDrawerOpen}>
        <SensitivePersonalInformationDrawer spi={spi} setSpi={setSpi} orderId={order.id} />
      </AppDrawer>

      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Please Fill Request Reason">
        <Box>
          <AppDropdownWithComment onChange={setReason} reasonOptions={RequestReasonsOptions} label="Reason" />
          <Button
            fullWidth
            variant="contained"
            onClick={handleRequest}
            disabled={isRequestButtonDisabled}
            sx={{ mt: 2 }}
          >
            Request Information
          </Button>
        </Box>
      </AppModal>
    </>
  );
};
