import {
  Describe,
  array,
  boolean,
  enums,
  nullable,
  number,
  object,
  pattern,
  string,
  union,
  unknown,
} from 'superstruct';
import { ICustomer } from '../customer.interface';
import { Languages } from '../language.type';
import { OrderClasses } from '@features/order/order-class.type';
import { EducationTypes } from '../education-type.type';
import { ISODateRegexp } from '@utils/dates/iso-string.type';

export const OrderTabStruct = object({
  id: number(),
  orderTypeId: nullable(number()),
  orderTypeName: nullable(enums(Object.values(OrderClasses))), // можно заменить на string() если OrderClassType — строка
});

export const CustomerStruct: Describe<ICustomer> = object({
  // Customer Details
  id: number(),
  firstName: string(),
  lastName: string(),
  preferredName: nullable(string()),
  crn: nullable(string()),
  fundingBodyId: nullable(number()),
  notes: nullable(string()),
  hasAccount: boolean(),
  extraMile: boolean(),
  isAccountSuspended: boolean(),
  externalImmyBotId: nullable(number()),
  labels: array(number()),

  // Contact Details
  address: nullable(string()),
  addressLine2: nullable(string()),
  addressLine3: nullable(string()),
  city: nullable(string()),
  country: nullable(string()),
  county: nullable(string()),
  altNumber: nullable(string()),
  contactNumber: nullable(string()),
  email: string(),
  postcode: nullable(string()),
  preferredLanguage: array(enums(Object.values(Languages))),
  prefCommunicationWay: array(number()),

  // Course Details
  isDataExchange: boolean(),
  courseDuration: nullable(number()),
  courseIndicativeSupportStartDate: nullable(pattern(string(), ISODateRegexp)),
  courseIndicativeSupportEndDate: nullable(pattern(string(), ISODateRegexp)),
  courseModeOfStudy: nullable(string()),
  courseName: nullable(string()),
  courseYear: nullable(number()),
  academicYear: union([string(), nullable(number())]),
  consentToPay: boolean(),
  educationType: nullable(enums(Object.values(EducationTypes))),
  hepId: nullable(number()),

  // Orders
  ordersTabs: array(OrderTabStruct),

  // Not used
  nmhAllocation: array(unknown()),
  hepName: unknown(),
});
