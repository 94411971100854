import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Box, Chip, CircularProgress, Link, Stack, Typography } from '@mui/material';
import { useAppTitle } from '@hooks/use-app-title';
import { RouteParamsType } from '@routes/route-params.type';
import { useCustomer } from './use-customer';
import { SaveCustomerForm } from './save-customer-form/SaveCustomerForm';
import { CopyCustomerInfo } from './CopyCustomerInfo';
import { CustomerOrders } from './CustomerOrders';
import { CreateOrResetAccount } from './create-or-reset-account/CreateOrResetAccount';
import { SuspendOrResumeAccount } from './suspend-or-resume-account/SuspendOrResumeAccount';
import { AppDrawer } from '@components/AppDrawer';
import { useToggle } from '@hooks/use-toggle';
import { CopyToClipboard } from '@components/CopyToClipboard';
import { BackendLabelGroup } from '@features/backend-label/BackendLabelGroup';

export const Customer: React.FC = () => {
  const { customer, isLoading: isCustomerLoading } = useCustomer();
  const { orderIdString } = useParams<RouteParamsType>();
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle();

  const title = customer ? `${customer.firstName} ${customer.lastName}` : 'Customer';
  useAppTitle(title);

  if (isCustomerLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  if (!customer) {
    return <Alert severity="warning">Customer not found.</Alert>;
  }

  const customerNumbers = `${customer.id}/${orderIdString}`;
  const customerName = `${customer.firstName} ${customer.lastName}`;
  const textToCopy = `${customerNumbers} ${customerName}`;
  const customerTitle = customer.preferredName ? `${customerName} (${customer.preferredName})` : customerName;

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
        <Typography variant="h4">
          <CopyToClipboard textToCopy={textToCopy}>{customerNumbers}</CopyToClipboard>
          <Link onClick={toggleIsDrawerOpen} sx={{ cursor: 'pointer', ml: 1, textDecorationStyle: 'dashed' }}>
            {customerTitle}
          </Link>
        </Typography>
        <Box>
          <Chip
            label={customer.hasAccount ? 'Account' : 'No Account'}
            variant="outlined"
            color={customer.hasAccount ? 'primary' : 'default'}
            size="small"
          />
        </Box>
        {customer.extraMile && (
          <Box>
            <Chip label="Extra Mile" variant="outlined" size="small" />
          </Box>
        )}
        {customer.labels.length > 0 && (
          <BackendLabelGroup labels={customer.labels} optionKey="labelOptions" variant="outlined" />
        )}
      </Stack>

      <CustomerOrders ordersTabs={customer.ordersTabs} />
      <AppDrawer open={isDrawerOpen} onClose={toggleIsDrawerOpen}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h4">
            {customer.firstName} {customer.lastName}
          </Typography>
          <CopyCustomerInfo customer={customer} />
        </Stack>
        <SaveCustomerForm
          customer={customer}
          ActionSlot={
            <>
              <CreateOrResetAccount customer={customer} />
              <SuspendOrResumeAccount customerId={customer.id} isInitiallySuspended={customer.isAccountSuspended} />
            </>
          }
        />
      </AppDrawer>
    </>
  );
};
