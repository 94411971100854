import { Describe, array, assign, boolean, enums, nullable, number, object, omit, pattern, string } from 'superstruct';
import { ISODateTimeRegexp } from '@utils/dates/iso-string.type';
import { ISession, ISessionSummary } from '@features/sessions/session.interface';
import { ElectronicInvoiceLogStruct } from '@features/electronic-invoice/electronic-invoice-struct';
import { ITimesheetInfo } from '@features/sessions/timesheet-info/timesheet-info.interface';

const TimesheetInfoStruct: Describe<ITimesheetInfo> = object({
  isUploadAllowed: boolean(),
  signedBy: nullable(array(enums(['student', 'tutor']))),
  downloadPath: nullable(string()),
});

export const SessionStruct: Describe<ISession> = object({
  id: number(),
  nmhAllocationId: nullable(number()),
  userId: nullable(number()),
  tutorId: nullable(number()),
  createdAt: pattern(string(), ISODateTimeRegexp),
  startMoment: pattern(string(), ISODateTimeRegexp),
  duration: number(),
  isCancelled: boolean(),
  isPaid: boolean(),
  funderInvoice: nullable(string()),
  supplierInvoice: nullable(string()),
  feedbackAvgSummary: nullable(number()),
  feedbackId: nullable(number()),
  timesheetInfo: TimesheetInfoStruct,
  summary: nullable(string()),
  electronicInvoice: nullable(string()),
  electronicInvoiceLogs: array(ElectronicInvoiceLogStruct),
  cancellationReason: nullable(string()),
  cancellationDate: nullable(pattern(string(), ISODateTimeRegexp)),
  offeredDate: nullable(pattern(string(), ISODateTimeRegexp)),
});

export const SessionSummaryStruct: Describe<ISessionSummary> = assign(
  omit(SessionStruct, ['timesheetInfo']),
  object({
    customerId: number(),
    orderId: number(),
    signedByTutor: boolean(),
    signedByStudent: boolean(),
  }),
);
