import React, { FC } from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { isUserDisabled } from '@features/backend-label/user-label.interface';
import { OrderCancelledWarning } from '@features/order/shared/cancel-or-restore-order/OrderCancelledWarning';
import { CancelOrRestoreOrder } from '@features/order/shared/cancel-or-restore-order/CancelOrRestoreOrder';
import { useSaveOrderForm } from '../use-save-order-form';
import { sanitize, serialize } from './form.config';
import { IOrderAnnualSupportCharge } from './annual-support-charge-order.interface';
import { AnnualSupportChargeList } from './annual-support-charge-list/AnnualSupportChargeList';

type Props = {
  order: IOrderAnnualSupportCharge;
};

export const OrderAnnualSupportCharge: FC<Props> = ({ order }) => {
  const { control, submitHandler, isButtonDisabled, operatorOptions, isDropdownsLoading, statusOptions } =
    useSaveOrderForm(order, sanitize, serialize);

  return (
    <Box>
      <OrderCancelledWarning orderId={order.id} position="top" />
      <form onSubmit={submitHandler}>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
          <Grid item xs={4} md={4} lg={3}>
            <ControlledAutocomplete
              control={control}
              name="userId"
              label="Operator"
              options={operatorOptions}
              getOptionDisabled={isUserDisabled}
              isLoading={isDropdownsLoading}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={3}>
            <ControlledAutocomplete
              control={control}
              name="orderStatusId"
              options={statusOptions}
              label="Order Status"
              isLoading={isDropdownsLoading}
              groupBy={(option): string => option.orderType}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledFileField
              fullWidth
              control={control}
              name="acceptanceLetterFile"
              label="Upload Acceptance"
              fileDetails={order.acceptanceFilePath}
              buttonLabel="Preview Acceptance"
              inputProps={{
                accept: '.pdf',
              }}
              isPreview
            />
          </Grid>
        </Grid>
        <Box>
          <AnnualSupportChargeList order={order} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <ControlledTextField control={control} name="notes" label="Notes" multiline minRows={3} fullWidth />
        </Box>

        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <CancelOrRestoreOrder orderId={order.id} />
          <Button sx={{ flexGrow: 1 }} variant="contained" size="large" type="submit" disabled={isButtonDisabled}>
            Save Order
          </Button>
        </Stack>
      </form>
      <OrderCancelledWarning orderId={order.id} position="bottom" />
    </Box>
  );
};
