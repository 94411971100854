import { useGenerateAnnualSupportChargeByQuoteMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { AnnualSupportChargeActions } from './annual-support-charge.slice';

type HookResult = {
  generateAnnualSupportChargeByQuote: (orderId: number, quoteId: string) => Promise<void>;
} & RequestStatusFlags;

export function useGenerateAnnualSupportChargeByQuote(): HookResult {
  const [sendMutation, flags] = useGenerateAnnualSupportChargeByQuoteMutation();
  const dispatch = useAppDispatch();

  const generateAnnualSupportChargeByQuote = async (orderId: number, quoteId: string): Promise<void> => {
    const result = await sendMutation({ orderId, quoteId }).unwrap();
    if (result) {
      dispatch(AnnualSupportChargeActions.upsertMany(result.annualSupportCharges));
    }
  };

  return { generateAnnualSupportChargeByQuote, ...flags };
}
