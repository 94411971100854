import { IBackendLabelOption } from '@features/backend-label/backend-label.type';

export const EducationTypes = {
  UnderGraduate: 0,
  PostGraduateMasters: 1,
  PostGraduate: 2,
  PostGraduateDoctorate: 3,
} as const;

type Keys = keyof typeof EducationTypes;

export type EducationTypeType = (typeof EducationTypes)[Keys];

export const EducationTypeOptions: IBackendLabelOption<EducationTypeType>[] = [
  {
    value: EducationTypes.UnderGraduate,
    label: 'UnderGraduate',
  },
  {
    value: EducationTypes.PostGraduateMasters,
    label: 'PostGraduate Masters',
  },
  {
    value: EducationTypes.PostGraduate,
    label: 'PostGraduate',
  },
  {
    value: EducationTypes.PostGraduateDoctorate,
    label: 'PostGraduate Doctorate',
  },
];
