import React from 'react';
import { AppPaper } from '@components/AppPaper';
import { Order } from '@features/order/Order';
import { Customer } from './Customer';
import { useAppTitle } from '@hooks/use-app-title';

export const CustomerPage: React.FC = () => {
  useAppTitle('Customer');
  return (
    <AppPaper sx={{ backgroundColor: 'var(--color-gray)' }}>
      <Customer />
      <Order />
    </AppPaper>
  );
};
