import React from 'react';
import moment from 'moment-timezone';
import { Button, Stack } from '@mui/material';
import { ModalChildProps } from '@components/AppModal';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDateTimePicker } from '@components/inputs/ControlledDateTimePicker';
import { ISession } from '@features/sessions/session.interface';
import { useSaveSessionForm } from './use-save-session-form';
import { ISODateString } from '@utils/dates/iso-string.type';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { WhyDisabled } from '@components/WhyDisabled';

type Props = {
  session: ISession | void;
  allocationId: number;
  endDate: ISODateString | null;
} & ModalChildProps;

export const SaveSessionForm: React.FC<Props> = ({ session, allocationId, endDate, onClose }) => {
  const {
    submitHandler,
    buttonText,
    control,
    sessionsCancellationReasonOptions,
    isLabelsLoading,
    isCancelled,
    isInvoiceDisabled,
    isDisabled,
    whyDisabledReason,
  } = useSaveSessionForm(session, allocationId, onClose);

  const endDateMoment = moment(endDate);

  return (
    <form onSubmit={submitHandler}>
      <Stack direction="column" spacing={2}>
        <ControlledDateTimePicker
          control={control}
          name="startMoment"
          label="Start Date Time"
          minutesStep={5}
          maxDate={endDateMoment}
          required
        />
        <ControlledTextField
          control={control}
          name="duration"
          label="Duration"
          required
          inputProps={{ inputMode: 'numeric' }}
          rules={{ required: 'Duration is required' }}
        />
        <ControlledDateTimePicker
          control={control}
          name="offeredDate"
          label="Offered Date Time"
          minutesStep={5}
          maxDate={endDateMoment}
          required
        />
        <ControlledToggleButton
          control={control}
          name="isCancelled"
          getLabel={(value: boolean): string => (value ? 'Cancelled' : 'Not Cancelled')}
        />
        {isCancelled && (
          <ControlledDateTimePicker
            control={control}
            name="cancellationDate"
            label="Cancellation Date Time"
            minutesStep={5}
            required
          />
        )}
        {isCancelled && (
          <ControlledAutocomplete
            control={control}
            name="cancellationReason"
            options={sessionsCancellationReasonOptions}
            label="Cancellation Reason"
            isLoading={isLabelsLoading}
            required
            rules={{ required: 'Cancellation Reason is required' }}
          />
        )}
        <WhyDisabled disabled={isInvoiceDisabled} title="Cancelled more than 24 hours ahead. Cannot be charged.">
          <ControlledTextField
            control={control}
            name="funderInvoice"
            label="Funder Invoice"
            disabled={isInvoiceDisabled}
            fullWidth
          />
        </WhyDisabled>
        <WhyDisabled disabled={isInvoiceDisabled} title="Cancelled more than 24 hours ahead. Cannot be charged.">
          <ControlledTextField
            control={control}
            name="supplierInvoice"
            label="Supplier Invoice"
            disabled={isInvoiceDisabled}
            fullWidth
          />
        </WhyDisabled>
        <WhyDisabled disabled={isDisabled} title={whyDisabledReason}>
          <Button variant="contained" size="large" type="submit" fullWidth disabled={isDisabled}>
            {buttonText}
          </Button>
        </WhyDisabled>
      </Stack>
    </form>
  );
};
