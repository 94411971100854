import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const Languages = {
  British: 0,
  Welsh: 1,
} as const;

type Keys = keyof typeof Languages;

export type LanguagesType = (typeof Languages)[Keys];

export const LanguagesOptions = createEnumOptions(Languages);
