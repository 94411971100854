import { ISODateString } from '@utils/dates/iso-string.type';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';
import { IOrderNeedsAssessment } from '../order-needs-assessment.interface';
import { Moment } from 'moment-timezone';

export type FormModel = Pick<IOrderNeedsAssessment, 'disabilityTypeIds' | 'conditionIds'> & {
  medicalEvidenceFiles: File | FileList | null;
  preAssessmentFormFile: File | null;
  narFile: File | null;
  dateOfBirth: Moment;
  dsa1pdfFile: File | null;
  evidNacdDisabilityFlag: boolean;
  rejectedDisabilityConditions: string | null;
  rejectedDisabilityTypes: string | null;
};

export type ApiModel = Pick<IOrderNeedsAssessment, 'disabilityTypeIds' | 'conditionIds'> & {
  dsa1pdfFile: File | null;
  medicalEvidenceFiles: File | FileList | null;
  preAssessmentFormFile: File | null;
  narFile: File | null;
  dateOfBirth: ISODateString;
  evidNacdDisabilityFlag: boolean;
  rejectedDisabilityConditions: string | null;
  rejectedDisabilityTypes: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  disabilityTypeIds: { type: 'backend-label', optionKey: 'disabilityTypeOptions', multiple: true, initial: [] },
  conditionIds: { type: 'backend-label', optionKey: 'conditionOptions', multiple: true, initial: [] },
  dsa1pdfFile: { type: 'transportable', initial: null },
  medicalEvidenceFiles: { type: 'transportable', initial: null },
  preAssessmentFormFile: { type: 'transportable', initial: null },
  narFile: { type: 'transportable', initial: null },
  dateOfBirth: { type: 'date', initial: null },
  evidNacdDisabilityFlag: { type: 'transportable', initial: false },
  rejectedDisabilityConditions: { type: 'transportable', initial: null },
  rejectedDisabilityTypes: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  disabilityTypeIds: Serializers.backendValue,
  conditionIds: Serializers.backendValue,
  dsa1pdfFile: Serializers.file,
  medicalEvidenceFiles: Serializers.file,
  preAssessmentFormFile: Serializers.file,
  dateOfBirth: Serializers.date,
  narFile: Serializers.file,
  evidNacdDisabilityFlag: Serializers.boolean,
  rejectedDisabilityConditions: Serializers.stringNullable,
  rejectedDisabilityTypes: Serializers.stringNullable,
});
