import { Moment } from 'moment-timezone';
import { ISODateString } from '@utils/dates/iso-string.type';
import { Serializers } from '@utils/forms/serializers';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';

export type FormModel = {
  pickUpFrom: Moment | null;
  pickUpTo: Moment | null;
  organisationId: Array<number | null>;
  dipReference: string | null;
};

export type ApiModel = {
  pickUpFrom: ISODateString | null;
  pickUpTo: ISODateString | null;
  organisationId: Array<number | null>;
  dipReference: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  pickUpFrom: { type: 'date', initial: null },
  pickUpTo: { type: 'date', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  dipReference: { type: 'transportable', initial: '' },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  pickUpFrom: Serializers.date,
  pickUpTo: Serializers.date,
  organisationId: Serializers.array,
  dipReference: Serializers.stringNullable,
});
