import React, { FC, useState } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { AppModal } from '@components/AppModal';
import { useToggle } from '@hooks/use-toggle';
import { useGenerateAnnualSupportChargeByQuote } from './use-generate-annual-support-charge';

type Props = {
  orderId: number;
};

export const AddAnnualSupportChargeByQuote: FC<Props> = ({ orderId }) => {
  const [quoteId, setQuoteId] = useState<string>('');
  const [isOpen, toggleIsOpen] = useToggle();
  const { generateAnnualSupportChargeByQuote, isLoading } = useGenerateAnnualSupportChargeByQuote();

  function handleQuoteIdChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setQuoteId(event.target.value);
  }

  async function handleSubmit(event: React.SyntheticEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    generateAnnualSupportChargeByQuote(orderId, quoteId);
  }

  const isDisabled = quoteId.trim().length === 0;

  return (
    <>
      <Button variant="outlined" size="small" onClick={toggleIsOpen} startIcon={<AddIcon />}>
        Add ASC by Quote ID
      </Button>
      <AppModal open={isOpen} onClose={toggleIsOpen} title="Add ASC by Quote ID">
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField size="small" name="quoteId" label="Quote ID" value={quoteId} onChange={handleQuoteIdChange} />
            <AppLoadingButton type="submit" variant="contained" fullWidth isLoading={isLoading} disabled={isDisabled}>
              Add Asc
            </AppLoadingButton>
          </Stack>
        </form>
      </AppModal>
    </>
  );
};
